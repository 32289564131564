import api from './api';

class ConsolidadoArchivosService {
  
  getItems(cadenaBusqueda) {
    cadenaBusqueda = cadenaBusqueda ? cadenaBusqueda : ''
    return api.get(`/api/consolidado_archivo${cadenaBusqueda}`);
  }

  getItem(layout_id, id) {
    return api.get(`/api/layouts/${layout_id}/consolidado_archivo/${id}`);
  }

  newItem(layout_id, params) {
    return api.post(`/api/layouts/${layout_id}/consolidado_archivo`, params)
  }

  editItem(layout_id, params) {
    return api.put(`/api/layouts/${layout_id}/consolidado_archivo/${params.id}`, params)
  }
}

export default new ConsolidadoArchivosService();