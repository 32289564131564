<template>
  <div>
    <v-card elevation="2" tile>
      <v-card-title
        >Edición de Consolidado Archivos: {{ consolidacion.nombre }}</v-card-title
      >
      <v-divider></v-divider>
      <v-form v-model="valid">
        <v-container v-if="!loadingSave">

          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="consolidacion.nombre"
                label="Nombre de la Consolidación"
                :rules="
                  required.concat(
                    (v) => (v && v.length <= 100) || 'Máximo 100 caracteres'
                  )
                "
                :counter="100"
                hide-details="auto"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="consolidacion.descripcion"
                label="Descripción"
                :counter="250"
                hide-details="auto"
              ></v-text-field>
            </v-col>
          </v-row>

          
          <v-row>
            
            
            
            <v-col cols="12" md="6">
              <div
                v-if="loadingLayouts"
                class="spinner-border"
                role="status"
              ></div>
              <v-select
                v-if="!loadingLayouts"
                v-model="consolidacion.layout_id"
                :items="fuentes"
                :item-text="'nombre'"
                :item-value="'id'"
                @change="onChangeLayout($event)"
                attach
                label="Layout principal"
                :rules="required"
              ></v-select>
            </v-col>

            <v-col cols="12" md="6" v-if="consolidacion.layout_id">
              <div
                v-if="loadingLayouts"
                class="spinner-border"
                role="status"
              ></div>
              <v-combobox
                v-model="relacionesArray"
                :items="relaciones"
                label="Relaciones"
                :item-text="'nombre'"
                :item-value="'id'"
                multiple
                chips
                @change="onChangeRelaciones()"
              ></v-combobox>


            </v-col>

          </v-row>



          <v-row>
            <v-col cols="12" md="6">
              <v-switch v-model="consolidacion.activo" :label="`Estado`"></v-switch>
            </v-col>

            <v-col cols="12" md="6" v-if="0==1">
              <v-switch v-model="consolidacion.agrupar" :label="`Agrupar`"></v-switch>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="6" v-show="true" v-if="consolidacion.layout_id">
              <CButton
                v-if="!loadingSave"
                color="primary"
                class="px-4"
                @click="loadVariables()"
                >-> Salida</CButton
              >
            </v-col>
          </v-row>


          <v-row>
            <v-col cols="12" md="12">
              <div class="text-center" v-if="loadingTransformaciones">
                <div class="spinner-border" role="status"></div>
              </div>
              <v-data-table
                v-if="!loadingTransformaciones"
                :headers="headers"
                :items="transformacionesArray"
                class="elevation-1 relations_field"
              >

                <template v-slot:item.valorC="props">
                  <v-text-field
                    v-model="props.item.valorC"
                    label="Label Campo"
                    single-line
                    hide-details="auto"
                  ></v-text-field>
                </template>

                <template v-slot:item.valorD="props" v-if="consolidacion.agrupar && 0==1">
                  <v-select
                    v-model="props.item.valorD"
                    :items="agrupados"
                    :item-text="'nombre'"
                    :item-value="'nombre'"
                    attach
                    label="Agrupado Por"
                  ></v-select>
                </template>

                <template v-slot:item.habilitado="props">
                  <v-switch v-model="props.item.habilitado" :label="'Habilitado'"></v-switch>
                </template>

              </v-data-table>
            </v-col>
          </v-row>



        </v-container>
      </v-form>
      <v-divider></v-divider>
      
      <v-card-actions >
        <v-spacer></v-spacer>
        <CButton color="secondary" @click="preCancelar">Cancelar</CButton>&nbsp;
        <div v-if="loadingSave" class="spinner-border" role="status"></div>
        <CButton
          v-if="!loadingSave"
          color="primary"
          class="px-4"
          @click="save()"
          :disabled="!valid"
          >Guardar</CButton
        >
      </v-card-actions>
    </v-card>

    <!-- <pre>{{consulta}}</pre> -->

    <v-dialog v-model="dialog" max-width="420">
      <v-card>
        <v-card-title class="text-h5">
          ¿Desea cancelar la edición?
        </v-card-title>

        <v-card-text>
          Si cancela la edición los datos diligenciados no quedarán guardados.
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="red darken-1" text @click="goBack">
            Cancelar y volver
          </v-btn>

          <v-btn color="green darken-1" text @click="dialog = false">
            Continuar la edición
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import RelacionService from "@/services/relacion.service";
import ConsolidadoArchivosService from "@/services/consolidadoArchivos.service";
import LayoutService from "@/services/layout.service";
import ListaService from "@/services/lista.service";
import VariableService from "@/services/variable.service";
import EventBus from "@/common/EventBus";

import { mapState } from "vuex";

export default {
  data() {
    return {
      consolidacion: {
        nombre: undefined,
        descripcion: undefined,
        layout_id: undefined,
        relaciones: [],
        campos: [],
        agrupar: 0,
        activo: 1,
      },
      transformacionesArray: [],
      valid: false,
      rules: [
        (value) => !!value || "Requerido",
        (value) => (value && value.length >= 3) || "Mínimo 3 caracteres",
      ],
      required: [(value) => !!value || "Requerido"],
      fuentes: [],
      relaciones: [],
      relacionesArray: [],
      agrupados: ["NA","Agrupado","Suma","Promedio","Min","Max","Cuenta","Primero","Último"],
      layoutA: [],
      layoutB: [],
      headers: [
      { text: "Id Variable", value: "valorA", sortable: false },
        { text: "Variables", value: "valorB", sortable: false },
        { text: "Label Campo", value: "valorC", sortable: false },
        //{ text: "Agrupar Por", value: "valorD", sortable: false},
        { text: "Habilitado", value: "habilitado", sortable: false },
      ],
      loadingLayouts: false,
      loadingCategorias: false,
      loadingLabelsPanoramicos: false,
      loadingTransformaciones: false,
      loadingCampos: false,
      loadingRoles: false,
      loadingSave: false,
      message: "",
      dialog: false,
    };
  },
  computed: {
    ...mapState(["permissions"]),
  },
  watch: {
    "consulta.layout_id"(newVal) {
      this.fetchCampos("");
    },
  },
  mounted() {
    if (!this.permissions.includes("Edicion Consolidacion Archivos"))
      this.$router.push("/dashboard");

    if (localStorage.getItem("datos_consolidado_archivos")) {
      this.consolidacion = JSON.parse(localStorage.getItem("datos_consolidado_archivos"));
      this.transformacionesArray = JSON.parse(this.consolidacion.campos);
      this.relacionesArray = JSON.parse(this.consolidacion.relaciones);
    }

    this.fetchLayouts("")
  },
  methods: {
    save() {
      this.loadingCampos = false
      //inicio limpio relaciones vacias
      this.transformacionesArray.forEach(function(item, index, object){
        console.log(item);
        if(item.transformacion == ''){
          object.splice(index);
        }
      });
      this.consolidacion.campos = JSON.stringify(
        this.transformacionesArray
      );
      this.loadingSave = true;
      this.consolidacion.relaciones = JSON.stringify(
        this.relacionesArray
      );
      console.log("guardando Conciliado ------------------------------")
      console.log(this.consolidacion);
      this.consolidacion.agrupar=0;   //tmp por ahora voy a dejarlo deshabilitado
      if (this.consolidacion.hasOwnProperty("id")) {

        ConsolidadoArchivosService.editItem(this.consolidacion.layout_id, this.consolidacion).then(
          (response) => {
            console.log(response)
            this.$router.push({ path: `/cargue-archivos/consolidado-archivos` });
          },
          (error) => {
            this.content =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
          }
        );
      } else {
        ConsolidadoArchivosService.newItem(this.consolidacion.layout_id, this.consolidacion).then(
          (response) => {
            this.$router.push({ path: `/cargue-archivos/consolidado-archivos` });
          },
          (error) => {
            this.content =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
          }
        );
      }
    },
    fetchLayouts(cadenaBusqueda) {
      this.loadingLayouts = true;
      if (cadenaBusqueda == "") cadenaBusqueda = "?activo=1";
      else cadenaBusqueda = cadenaBusqueda + "&activo=1";
      LayoutService.getItems(cadenaBusqueda).then(
        (response) => {
          this.fuentes = response.data.map(function(x){
            return { id:x.id, nombre: x.id+'. '+x.nombre };
          });
          this.loadingLayouts = false;
        },
        (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          this.loadingLayouts = false;
        }
      );
    },
    fetchVariables(cadenaBusqueda,offset) {
      this.loadingTransformaciones = true;
      if(cadenaBusqueda=="")
        cadenaBusqueda="?activo=1";
      else
        cadenaBusqueda="?layout_id="+cadenaBusqueda+"&activo=1";
      //console.log(cadenaBusqueda)
      VariableService.getItems(cadenaBusqueda).then(
        (response) => {
          //console.log(response)
          response.data.forEach(x => {
            //console.log(x)
            this.transformacionesArray.push({
              valorA: x.id,
              valorB: x.nombre,
              valorC: x.nombre,
              valorD: '',
              habilitado:1
            });
          });
          this.loadingTransformaciones = false;
        },
        (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
            this.loadingTransformaciones = true;
        }
      );
    },
    agregarTransformacion() {
      this.fetchVariables(this.relacion.layout_id1,1);
      this.fetchVariables(this.relacion.layout_id2,2);
      this.transformacionesArray.push({
        valorA: "",
        valorB: "",
      });
    },
    deleteItem(transformacion) {
      let i = this.transformacionesArray.indexOf(transformacion);
      this.transformacionesArray.splice(i, 1);
    },
    preCancelar() {
      this.dialog = true;
    },
    goBack() {
      this.$router.push({ path: "/cargue-archivos/consolidado-archivos" });
    },
    fetchRelaciones(cadenaBusqueda) {
      this.loadingCampos = true;
      cadenaBusqueda="?activo=1";
      RelacionService.getItems(cadenaBusqueda).then(
        (response) => {
          console.log(response)
          this.relaciones=response.data
          console.log(this.relaciones)
          this.loadingCampos = false;
        },
        (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          this.loadingCampos = false;
        }
      );
    },
    onChangeLayout(event){
      console.log(event)
      this.fetchRelaciones(event);
    },
    onChangeRelaciones(){
      //console.log(this.consolidacion.relaciones)
    },
    loadVariables(){
      console.log("calculando las variables de los layouts")
      let layouts_array=[]
      this.relacionesArray.forEach(rel => {
        //console.log(rel)
        layouts_array.push(rel.layout_id1)
        layouts_array.push(rel.layout_id2)
      });
      //console.log(layouts_array)
      // Limpiar para no tener layouts repetidos
      layouts_array=layouts_array.filter((item,index) => layouts_array.indexOf(item) === index)
      //console.log(layouts_array)
      layouts_array.forEach(ly =>{
        this.fetchVariables(ly);
      });
      
    },
  },
};
</script>

<style>
</style>

